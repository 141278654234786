import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../../../SGRE-shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class InviteUserService {

  private isSelectedRequestSubject = new BehaviorSubject<boolean>(false);
  toggleRequest$ = this.isSelectedRequestSubject.asObservable();
  public inviteUser = new BehaviorSubject<any>({
    firstName: '',
    lastName: '',
    email: '',
  });

  constructor(
    private readonly http: HttpClient,
    private readonly apiService: ApiService,
  ) { }

  setSelected(toggleRequest: boolean) {
    this.isSelectedRequestSubject.next(toggleRequest)
  }

  setMyProfileObject(userData: any) {
    this.inviteUser.next(userData)
  }

  public getCompanyList(requestObject: any): Observable<any> {
    return this.apiService.getCompanyList(requestObject, {}).pipe((res: any) => res);
  }

  public getinviteUserList(requestObject: any): Observable<any> {
    return this.apiService.getLegalEntityList(requestObject.currentPage, requestObject.fields, requestObject.pageSize, requestObject.userID);
  }

  postInviteUserInternal(requestObject: any, requestBody: any[]) {
    let params = new HttpParams();
    if (requestObject.userType === 'SGRE' && requestObject?.userRole === 'sales') {
      params = params.append('email', requestObject.email);
      params = params.append('sgreAdmin', 'false');
    }
    else if (requestObject.userType === 'SGRE' && requestObject?.userRole === 'administrator') {
      params = params.append('email', requestObject.email);
      params = params.append('sgreAdmin', 'true')
    }
    return this.apiService.postLegalEntityListInternal(requestBody, params).pipe(map(res => res,
    ));
  }

  submitInviteUserExt(requestObject: any, requestBody: any[]) {
    let params = new HttpParams();
    if (requestObject?.role === 'SGRE Admin') {
      params = params.append('isFromSameCompany', 'false');
      params = params.append('invitationType', 'int-to-ext')
    } else {
      params = params.append('isFromSameCompany', requestObject.companyExt);
      params = params.append('invitationType', 'ext-to-ext')
    }
    params = params.append('email', requestObject.email);
    params = params.append('firstName', requestObject.firstName);
    params = params.append('company', requestObject?.company)
    params = params.append('lastName', requestObject.lastName);
    return this.apiService.postLegalEntityListExteral(requestBody, params).pipe(map(res => res,
    ));
  }
}
